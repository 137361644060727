<template>
     <div v-if="list.length" class="list-custom"
        :class="{ 'background-custom' : hasBackground }"
    >
      <h3 class="title-modal-recommend" v-if="isInModal && titleModal">
        <template v-if="!titleModal.includes('<br>')">
          {{ titleModal }}
        </template>
        <template v-else>
          <p v-for="title in titleModal.split('<br>')" :key="title">
            {{ title }}
          </p>
        </template>
      </h3>
      <div
          class="block-custom"
          :class="{'block-shadow': hasShadow , 'change-background': isChangeBackground, 'in-modal': isInModal}"
          v-for="(item, index) in list"
          :key="index"
          @click="handleRedirectAdDetail(item.id || 1)"
          >
        <!-- MARK COMPLETED -->
        <img v-if="item.mission_completed && item.mission_completed.length && hasShowMark"
            class="mark-cate" src="../assets/images/mark-complete.svg"
            alt="mark"
            >

        <img :src="(item.file ? item.file.path : '') | imageEmpty" alt="">

        <!-- content custom -->
        <div class="content-cate">
          <h4 class="line-clamp-02">{{ item.title }}</h4>
          <span class="description" v-if="showDesc" :class="{'line-clamp-01' : isVertical, 'line-clamp-02' : isHorizontal }" >{{ item.list_description }}</span>
          <div class="point-back" :class="{ 'campaign-amount-horizontal': isHorizontalAmount }">
            <template v-if="item.is_campaign === 1
                        && checkDateBetween(item.campaign_start_datetime, item.campaign_end_datetime)">
              <span v-if="showDel">通常</span>
              <del v-if="showDel">
                <template v-if="item.point_type === 1">
                  {{item.normal_pointback_amount | numberWithCommas(',', 'P')}}
                </template>
                <template v-else>
                  {{item.normal_pointback_rate | numberWithCommas(',', '%P')}}
                </template>
              </del>
              <template v-else>
                  <p v-if="item.point_type === 2">通常 {{ item.normal_pointback_rate | numberWithCommas(',', '%P') }}</p>
                  <p v-else>通常 {{ item.normal_pointback_amount | numberWithCommas(',', 'P') }}</p>
              </template>
            </template>

            <strong v-if="item.is_campaign === 1
                        && checkDateBetween(item.campaign_start_datetime, item.campaign_end_datetime)">
              <template v-if="item.point_type === 2">{{ item.campaign_pointback_rate | numberWithCommas(',') }}<span>%</span>P</template>
              <template v-else>{{ item.campaign_pointback_amount | numberWithCommas(',', 'P') }}</template>
            </strong>
            <strong v-else>
              <template v-if="item.point_type === 2">{{ item.normal_pointback_rate | numberWithCommas(',') }}<span>%</span>P</template>
              <template v-else>{{ item.normal_pointback_amount | numberWithCommas(',', 'P') }}</template>
            </strong>
          </div>
        </div>
      </div>
        <!-- button show more -->
        <div v-if="list.length > 0 && hasShowMore" class="paginate-container">
          <button class="btn-show-more" @click="handleShowMore">
            もっと見る
          </button>
        </div>
    </div>
</template>

<script>
import Common from '@/mixins/common.mixin'
import store from '@/store'

export default {
  mixins: [Common],

  props: {
    list: {
      type: Array,
      default: () => []
    },
    hasShowMore: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      required: true
    },
    hasBackground: {
      type: Boolean,
      default: false
    },
    showDel: {
      type: Boolean,
      default: false
    },
    isHorizontalAmount: {
      type: Boolean,
      default: false
    },
    hasShadow: {
      type: Boolean,
      default: false
    },
    hasShowMark: {
      type: Boolean,
      default: false
    },
    isChangeBackground: {
      type: Boolean,
      default: false
    },
    isInModal: {
      type: Boolean,
      default: false
    },
    titleModal: {
      type: String,
      default: ''
    },
    showDesc: {
      type: Boolean,
      default: false
    },
    keepModal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isVertical () {
      return this.mode === 'vertical'
    },
    isHorizontal () {
      return this.mode === 'horizontal'
    }
  },
  methods: {
    handleShowMore () {
      this.$emit('handle-link')
    },
    handleRedirectAdDetail (id) {
      if (this.keepModal) {
        store.dispatch('advertises/setKeepModal', true)
      }
      if (this.checkWebview()) {
        const url = process.env.VUE_APP_USER_DOMAIN + 'advertising-detail/' + id
        this.triggerEventApp(
          'OPEN_WEBVIEW',
          { url: url }
        )
      } else {
        this.$router.push({ name: 'AdvertisingDetail', params: { id } })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.list-custom {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 120px;
  &:has(.in-modal) {
    & {
      margin: 0;
    }
  }
  @media #{$info-phone} {
    margin: 0 0 36px;
  }
  &.list-cate-like {
    margin: 0 -4px 125px;
    @media #{$info-phone} {
      margin: 0 0 29px;
    }
  }
  .block-custom {
    max-width: calc(25% - 8px);
    margin: 0 4px 8px;
    width: 100%;
    position: relative;
    z-index: 1;
    padding: 16px;
    &:hover {
      cursor: pointer;
    }
    /* background: #E7E8DE; */
    background: #ffffff;
    border-radius: 6px;
    &.block-shadow {
      box-shadow: 0px 0px 6px #00000033;
    }
    .cate-pink & {
      /* background: #F1E9EB; */
      &.change-background {
        background: #E5CADB;
      }
    }
    .cate-orange & {
       &.change-background {
        background: #F5F0ED;
      }
    }
    .cate-green & {
      &.change-background {
        background: #E7E8DE;
      }
    }
    @media #{$info-tablet-horizontal} {
      max-width: calc(33.3% - 8px);
    }
    @media #{$info-tablet-vertical} {
      max-width: calc(50% - 8px);
    }
    .block-vertical & {
      min-height: 346px;
      @media #{$info-phone} {
        max-width: calc(50% - 8px);
        min-height: 280px;
        padding: 9px;
      }
    }
    .block-horizontal & {
      max-width: calc(50% - 8px);
      min-height: 131px;
      display: flex;
      @media #{$info-tablet-horizontal} {
        min-height: 150px;
      }
      @media #{$info-tablet-vertical} {
        max-width: 100%;
        margin: 0 0 7px;
      }
     @media #{$info-phone} {
        max-width: 100%;
        margin: 0 0 7px;
        &:nth-child(n + 5) {
          display: none;
        }
      }

    }
    &.in-modal {
      min-height: 280px;
      max-width: calc(25% - 8px);
      .block-vertical & {
        @media #{$info-phone} {
          max-width: calc(50% - 8px);
          min-height: 250px;
          &:nth-child(n + 4) {
            display: none;
          }
        }
        @media (max-width: 300px) {
          max-width: 100%;
        }
        img {
          width: 150px;
          height: 130px
        }
        padding: 12px
      }
      .content-cate {
        text-align: left;
        span {
          display: none;
        }
      }
    }
    .bg-shadow {
      position: absolute;
      right: 16px;
      top: 16px;
      border-radius: 6px;
      width: 144px;
      height: 144px;
      background: #BDBFAE;
      z-index: -1;
      opacity: .25;
      @media #{$info-phone} {
        width: 95px;
        height: 95px;
        right: unset;
        left: 18px;
        top: 9px;
      }
      .cate-pink & {
        background: #E5CADB;
      }
      .cate-orange & {
        background: #D98F6D;
        opacity: .1;
      }
    }
    .mark-cate {
      position: absolute;
      width: 83px;
      height: 83px;
      object-fit: fill;
      right: 10px;
      top: 11px;
      margin: 0;
      border: none;
      @media #{$info-phone} {
        width: 52px;
        height: 52px;
        right: 5px;
        top: 5px;
        opacity: .5;
      }
    }
    img {
      display: block;
      //object-fit: cover;
      border: 1px solid #e5eaf5;
      @media #{$info-phone} {
        margin: auto;
      }
      .block-vertical &, .block-horizontal & {
        &.mark-cate {
          width: 83px;
          height: 83px;
        }
      }
     .block-vertical & {
        width: 172px;
        height: 172px;
        margin: 0 auto 16px;
        @media #{$info-phone} {
          width: 140px;
          height: 140px;
          margin: auto;
        }
     }
     .block-horizontal & {
        width: 120px;
        height: 120px;
        // height: 100%;

     }
    }
    .content-cate {
      @media #{$info-phone} {
        flex: 1;
        /* margin-left: 20px; */
      }
      .block-horizontal & {
        margin-left: 20px;
      }
      h4 {
        font-family: $font-family-title-B;
        @include font-size(16px);
        margin-bottom: 8px;
        color: #7C934C;
        word-break: break-all;
        line-height: 1.3;
        @media #{$info-phone} {
          margin:7px 0;
          font-size: 16px;
        }
        .cate-pink & {
          color: #A54B67;
        }
        .cate-orange & {
          color: #C66C44;
        }
      }
      .description {
        color: #282828;
        @include font-size(12px);
        margin-bottom: 36px;
        word-break: break-all;
        @media #{$info-tablet-horizontal} {
          .block-horizontal & {
            margin: 15px 0 45px;
          }
        }
        @media #{$info-phone} {
          font-size: 13px;
          /* margin-bottom: 40px; */
          margin: 15px 0 50px;
          .block-horizontal & {
            margin: 15px 0 30px;
          }
        }
      }
      .point-back {
        position: absolute;
        color: #282828;
        bottom: 25px;
        right: 15px;
        text-align: right;
        @media #{$info-phone} {
          right: 9px;
          bottom: 8px;
        }
        .block-horizontal & {
          bottom: 10px;
        }
        &.campaign-amount-horizontal {
          display: flex;
          @media screen and (min-width: 901px) and (max-width: 1100px) {
            display: block;
            bottom: 8px;
          }
          @media #{$info-phone} {
            display: flex;
          }
          del {
            padding-left: 5px;
          }
        }
      }
      span, del {
        @include font-size(12px);
        @media #{$info-phone} {
          font-size: 12px;
        }
      }
      p {
        color: #282828;
        @include font-size(12px);
        text-align: right;
        @media #{$info-phone} {
          font-size: 11px;

        }
      }
      strong {
        font-family: $font-label;
        /* color: #6F8784; */
        color: #7C934C;
        @include font-size(28px);
        font-style: italic;
        font-weight: normal;
        line-height: 1;
        display: block;
        text-align: right;
        .cate-pink & {
          /* color: #C47E99; */
          color: #A54B67;
        }
        .cate-orange & {
          color: #D98F6D;
        }
        span {
          @include font-size(21px);
          @media #{$info-phone} {
            font-size: 18px;
          }
        }
      }
    }
  }
  .paginate-container {
    margin: 30px auto 0;
    width: 100%;
    .btn-show-more {
        font-family: $font-family-title;
        display: flex;
        background: #ffffff;
        color: #7C934C;
        &:hover {
          cursor: pointer;
        }
        @include font-size(18px);
        margin:0 auto;
        .cate-pink & {
          color: #A54B67;
        }
        .cate-orange & {
          color: #C66C44;
        }
        @media #{$info-phone} {
          font-size: 16px;
        }
      }
  }
}
.background-custom {
  position: relative;
  padding: 20px;
  width: 100%;
  &:after {
    content: '';
    position: absolute;
    width: -100%;
    left: 0;
    right: 0;
    height: 100%;
    top: 0;
    z-index: 0;
    background: #F1E9EB;
    border-radius: 6px;
    @media #{$info-phone} {
      left: -100%;
      right: -100%;
      display: none;
    }
  }
  @media #{$info-phone} {
    padding: 0;
  }
  &:has(.in-modal) {
    & {
      padding: 10px;
    }
    &:after {
      @media #{$info-phone} {
        left: 0;
        right: 0;
        display: block;
      }
    }
  }
}
.title-modal-recommend {
      width: 100%;
      z-index: 10;
      font-size: 26px;
      margin-bottom: 15px;
      color: #7C934C;
      font-weight: bold;
      @media #{$info-phone} {
        font-size: 18px;
      }
      @media (max-height:600px) {
        font-size: 18px;
      }
      .cate-pink & {
        color: #A54B67;
      }
    }
</style>
